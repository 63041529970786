<template>
    <div class="three wide column sidebar-container">
        <router-link :to="{name: 'home'}"> <img src="@/assets/images/CFE.png" alt /> </router-link>

        <div class="sidebarFolderContainer" :class="{'fixed-sidebar' : $route.name === 'folderEdit','fixed-sidebar-large' : $route.name === 'search'}">

            <div class="sidebar-item">
                <router-link :to="{name: 'dashboard'}">
                    <span class="label">
                        <i class="home icon"></i>DASHBOARD
                    </span>
                </router-link>
            </div>

            <div class="sidebar-item">
                <span class="label">
                    <i class="inbox icon"></i>Home
                </span>
                <div class="sub-items">
                    <div class="sub-item" v-for="action in action_list">
                        <router-link :to="{ name: 'home_action', params:{ action_id:action._id, action_name: action.name} }"> {{action.name}} </router-link>
                    </div>

                </div>
            </div>


            <div class="sidebar-item" v-if="current_user.type === 'admin' || current_user.type === 'manager'">
                <span class="label">
                    <i class="cog icon"></i>Administration
                </span>
                <div class="sub-items">
                    <div class="sub-item"> <router-link :to="{name: 'userList'}"> Gestion des Utilisateurs </router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'assuranceList'}"> Gestion des Assurances </router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'courtierList'}"> Gestion des Courtiers </router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'marqueList'}"> Gestion des Marques </router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'modelList'}"> Gestion des Modéles </router-link> </div>
<!--                    <div class="sub-item"> <router-link :to="{name: 'taskList'}"> Gestion des Tâches </router-link> </div>-->
                    <div class="sub-item"> <router-link :to="{name: 'actionList'}"> Gestion des Actions </router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'folderList'}"> Gestion des Dossiers </router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'desigFournitureList'}"> Gestion des Désignation Fourniture</router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'desigOeuvreList'}"> Gestion des Désignation main d'oeuvre</router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'expertAdverseList'}"> Gestion des Exprets Adverse</router-link> </div>
                    <div class="sub-item"> <router-link :to="{name: 'honoraireList'}"> gestion des honoraires</router-link> </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'sidebar',
    data(){
        return{
            action_list: [],
            maxHeight:250
        }
    },
    methods:{
        getActionList(){
            let match = { "receiver.username": this.current_user.username };
            if(this.current_user.type === 'dispatcher'){
                match = { "receiver._id": {$in: [this.current_user._id, 'dispatcher'] } };
            }
            match["action.task_status"] = "active" ;
            const data = {
                collection: this.$SHARED.collections.documents,
                pipeline: [
                    {$match: match},
                    {$group: {
                            _id: "$action.template",
                            name: {$first: "$action.name"}
                        }
                    },
                    {$sort: {_id: 1}}
                ]
            };
            this.$store.requestAggregate(data).then(result => {
                this.action_list = result;
            }, code => {
                this.$notify({
                    group: 'user-message',
                    type: 'warn',
                    text: this.$SHARED.messages.technical_error
                });
            })
        },
        // setSidebarHeight(){
        //      let sidebarHeight = $('.sidebar-container')
        //      let compHeight = sidebarHeight.height()
        //      $(window).resize(() => {
        //          console.log(`Hello ` + sidebarHeight.height())
        //          compHeight = sidebarHeight.height()
        //      })
        //     console.log(compHeight)
        //     if(compHeight > 150) this.maxHeight = compHeight - 65
        // }
    
    },
    computed:{
        current_user(){
            return this.$store.state.current_user;
        }
    },
    mounted() {
    //    this.setSidebarHeight()
        this.getActionList();
    }
};
</script>

<style scoped>
    a{
        color: hsla(0, 0%, 100%, 0.5);
        font-weight: 500;
    }
    .fixed-height{
        max-height: 551px
    }
    .router-link-active{
        color: #FFFFFF !important;
        font-weight: 600 !important;
    }
</style>